import Image from "next/image";
import Button from "@/components/ui/button";
import Link from "@/components/ui/Link";
import { getReadableImageUrl } from "@/lib/directus";
import { sanitizeHTML } from "@/lib/utils";

interface GridImageWithBtnProps {
  data?: {
    heading?: {
      text?: string;
      title?: string;
    };
    subheading?: {
      text?: string;
      title?: string; 
    };
    text?: {
      text?: string;
    };
    content_collection?: {
      id: string;
      site_content_collections_id: {
        collection: {
          collection: string;
          item: {
            //menu_items
            href: string;
            label: string;
              
            //images
            alt: string;
            image_file: {
              image: {
                id: string;
                filename_download: string;
              };
            };
              
          };
        }[];
      };
    }[];
    button?: {
      label?: string;
      href?: string;
    };
  };
}

export const fields = [
  "heading.text",
  "heading.title",

  "subheading.text", 
  "subheading.title",

  "text.text",

  "content_collection.id",
  "content_collection.site_content_collections_id.collection.collection",

  "content_collection.site_content_collections_id.collection.item:images.alt",
  "content_collection.site_content_collections_id.collection.item:images.image_file.image.id",
  "content_collection.site_content_collections_id.collection.item:images.image_file.image.filename_download",

  "content_collection.site_content_collections_id.collection.item:menu_items.href",
  "content_collection.site_content_collections_id.collection.item:menu_items.label",

  "button.label",
  "button.href"
] as const;

export default function GridImageWithBtn({ data }: GridImageWithBtnProps) {
  const heading = data?.heading?.title || "";
  const subheading = data?.subheading?.title || "";
  const description = data?.text?.text || "";
  const buttonLabel = data?.button?.label || "";
  const buttonHref = data?.button?.href || "";

  return (
    <section className="bg-[rgba(177,163,164,0.15)] py-12 md:py-16">
      <div className="container">
        <div className="mx-auto text-center mb-12">
          <div className="font-aileron font-bold uppercase text-sm tracking-[2px] text-black mb-4">
            {subheading}
          </div>
          <h2 className="font-freightBig text-3xl md:text-4xl lg:text-[44px] text-black leading-tight mb-4">
            {heading}
          </h2>
          <div 
            className="font-aileron text-base text-black"
            dangerouslySetInnerHTML={sanitizeHTML(description)}
          />
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-12 md:gap-16">
          {data?.content_collection?.map((collection, index) => {
            const imageItem = collection?.site_content_collections_id?.collection.find(item => item.collection === "images")?.item;
            const menuItem = collection?.site_content_collections_id?.collection.find(item => item.collection === "menu_items")?.item;
            const imageId = imageItem?.image_file?.image?.id || "";
            const imageFilename = imageItem?.image_file?.image?.filename_download || "";
            const imageAlt = imageItem?.alt || "";
            const menuItems = menuItem;


            return (
              <div key={index} className="flex flex-col gap-4 items-center">
                <div className="relative aspect-w-4 aspect-h-3 w-full">
                  <Image
                    src={getReadableImageUrl(imageId, imageFilename)}
                    alt={imageAlt}
                    fill
                    className="object-cover rounded-lg"
                    quality={95}
                    loading={"lazy"}
                  />
                </div>
                <Button variant="outline" className="w-fit" asChild>
                  <Link href={menuItems?.href || "#"}>
                    {menuItems?.label || ""}
                  </Link>
                </Button>
              </div>
            );
          })}
        </div>
        <div className="flex justify-center mt-12">
          <Button asChild>
            <Link href={buttonHref}>{buttonLabel}</Link>
          </Button>
        </div>
      </div>
    </section>
  );
}
